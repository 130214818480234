import { CREATE_CHAT, DISPLAY_CHAT_INPUT, OPEN_SESSION_MODAL} from "./types";

export interface IntialState {
    openSessionModel:null|number
    displayChatInput:null|number
}

const initialState: IntialState = {
    openSessionModel:null,
    displayChatInput:null
};

export default function campaignReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_CHAT:
      return {
        ...state,

      };
    case OPEN_SESSION_MODAL:
        return {
            ...state,
            openSessionModel:payload
        }
    case DISPLAY_CHAT_INPUT:
        return {
            ...state,
            displayChatInput:payload
        }
    default:
      return state;
  }
}
